* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  font-family: "brandon-grotesque", sans-serif;
}
